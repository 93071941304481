<template>
  <div v-loading.fullscreen.lock="loading"></div>
</template>

<script>
  import {HTTP} from '@/service/axios'
  import '@/service/Company'
  import AuthService from '@/service/oidcService'
  import jwt from 'jsonwebtoken'
  export default {
    data() {
      return {
        loading: true,
        data: null,
        verifyToken: null,
        ruleForm: {},
      }
    },
    computed: {
      refCode() {
        return this.$store.state.refer
      },
      useroidc() {
        return this.$store.state.useroidc
      },
    },
    methods: {
      async checkUserTerak(data) {
        try
        {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          let res = await HTTP.post(`check/user/from/ex1m`, {
            email: data,
          }).catch((e) => {
            console.log('error :', e)
            this.loading = false
            this.$router.push('/')
          })
          if (res.data.success) {
            if (!res.data.obj.statusAccount) {
              if (this.refCode) {
                this.$router.push(`/sign-up?app=ex1&code=${this.refCode}`)
              } else {
                this.$router.push(`/sign-up?app=ex1`)
              }
            } else {
              this.signin(res.data.obj.token)
            }
          } else {
            this.loading = false
            this.$router.push('/')
            this.$message.error(res.data.data)
          }
        } catch (error) {
          console.log('checkUserTerak Error :', error)
          this.loading = false
          this.$router.push('/')
          this.$message.error('Something went wrong')
        }
      },
      async signin ( token )
      {
        let userData = await this.getUserById(token)
        if (userData.data.success) {
          this.$store.commit('SET_TOKEN', token)
          this.ruleForm = userData.data.obj
          let update = false
          if (
            typeof this.$store.state.useroidc.profile.firstName !=
              'undefined' &&
            this.ruleForm.name !=
              this.$store.state.useroidc.profile.firstName.toString()
          ) {
            update = true
            this.ruleForm.name = this.$store.state.useroidc.profile.firstName.toString()
          }

          if (
            typeof this.$store.state.useroidc.profile.lastName != 'undefined' &&
            this.ruleForm.surname !=
              this.$store.state.useroidc.profile.lastName.toString()
          ) {
            update = true
            this.ruleForm.surname = this.$store.state.useroidc.profile.lastName.toString()
          }

          if (
            typeof this.$store.state.useroidc.profile.phone != 'undefined' &&
            this.ruleForm.mobilephone !=
              this.$store.state.useroidc.profile.phone.toString()
          ) {
            update = true
            this.ruleForm.mobilephone = this.$store.state.useroidc.profile.phone.toString()
          }

          if (update) {
            await this.editProfile()
            userData = await this.getUserById(token)
          }

          this.$store.commit(
            'SET_USER',
            JSON.parse(JSON.stringify(userData.data.obj))
          )

          if (userData.data.obj.role == 0) {
            let checkData = await this.checkCompanyData(userData.data.obj)
            if (checkData) {
              let url = this.refCode
                ? `/edit-company&code=${this.refCode}`
                : `/edit-company`
              this.$router.push(url).catch((err) => {})
            } else {
              let url = `/result?${
                userData.data.obj.recommendStatus == 1 ? '' : 'user=new'
              }`
              if (this.refCode) {
                this.$router
                  .push(`${url}&code=${this.refCode}`)
                  .catch((err) => {})
              } else {
                this.$router.push(url).catch((err) => {})
              }
            }
          } else {
            this.directUrl(userData.data.obj, '')
          }
        } else {
          this.$router.push('/')
          this.loading = false
        }
      },
      async getAccessToken() {
        try {
          let obj = {
            code: this.$route.query.code,
            grant_type: 'authorization_code',
            scope: this.$route.query.scope,
          }
          let res = await AuthService.getToken(obj)
          return res
        } catch (error) {
          this.loading = false
          this.$message.error('Something went wrong')
          console.log('getAccessToken Error :', error)
        }
      },
      async signinCallBack() {
        try {
          // let res = await this.getAccessToken();
          // console.log("getAccessToken :", res);
          let result = await AuthService.getUser()
          this.$store.commit('SET_USER_OIDC', result)

          this.checkUserTerak(result.profile.email)
        } catch (error) {
          console.log('signinCallBack', error)
        }
      },
      async signinRedirectCallback() {
        try {
          let result = await AuthService.signinRedirectCallback()
            .then((e) => {
              return e
            })
            .catch((e) => {
              console.log('Error signinRedirectCallback : ', e.message)
              if (e.message == 'No state in response') {
                //this.autoSugnin();
              } else {
                this.$router.push('/')
              }
            })
          // let getNewToken = this.getAccessToken();
          this.$store.commit('SET_USER_OIDC', result)
          this.checkUserTerak(result.profile.email)
        } catch (e) {
          console.log('mounted :', e)
          this.data = 'Error'
          this.loading = false
        }
      },
      async editProfile() {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': this.$csrfToken,
          },
        }
        let formData = new FormData()
        formData.append('userId', this.ruleForm.id)

        let profile =
          this.ruleForm.profileImg &&
          typeof this.ruleForm.profileImg.raw != 'undefined'
            ? this.ruleForm.profileImg.name
            : this.ruleForm.profileImg
            ? this.ruleForm.profileImg
            : ''
        formData.append('profileImg', profile)
        if (
          this.ruleForm.profileImg &&
          typeof this.ruleForm.profileImg.raw != 'undefined'
        ) {
          formData.append('file', this.ruleForm.profileImg.raw)
        } else {
          formData.append('file', '1')
        }

        formData.append(
          'email',
          typeof this.ruleForm.email != 'undefined' ? this.ruleForm.email : ''
        )
        formData.append(
          'password',
          typeof this.ruleForm.password != 'undefined'
            ? this.ruleForm.password
            : ''
        )
        formData.append(
          'titleName',
          typeof this.ruleForm.titleName != 'undefined'
            ? this.ruleForm.titleName
            : 0
        )
        formData.append(
          'name',
          typeof this.ruleForm.name != 'undefined' ? this.ruleForm.name : ''
        )
        formData.append(
          'surname',
          typeof this.ruleForm.surname != 'undefined'
            ? this.ruleForm.surname
            : ''
        )
        formData.append(
          'gender',
          typeof this.ruleForm.gender != 'undefined' ? this.ruleForm.gender : 0
        )
        formData.append(
          'age',
          typeof this.ruleForm.age != 'undefined' ? this.ruleForm.age : 0
        )
        formData.append(
          'education',
          typeof this.ruleForm.education != 'undefined'
            ? this.ruleForm.education
            : 0
        )
        formData.append(
          'mobilephone',
          typeof this.ruleForm.mobilephone != 'undefined'
            ? this.ruleForm.mobilephone
            : ''
        )
        formData.append(
          'companyName',
          typeof this.ruleForm.companyName != 'undefined'
            ? this.ruleForm.companyName
            : ''
        )
        formData.append(
          'corporateNumber',
          typeof this.ruleForm.corporateNumber != 'undefined'
            ? this.ruleForm.corporateNumber
            : ''
        )
        formData.append(
          'position',
          typeof this.ruleForm.position != 'undefined'
            ? this.ruleForm.position
            : 0
        )
        formData.append(
          'businessType',
          typeof this.ruleForm.businessType != 'undefined'
            ? this.ruleForm.businessType
            : 0
        )
        formData.append(
          'cateIndustryType',
          typeof this.ruleForm.cateIndustryType != 'undefined' &&
            this.ruleForm.cateIndustryType != null &&
            this.ruleForm.cateIndustryType != ''
            ? this.ruleForm.cateIndustryType
            : 0
        )
        formData.append(
          'industryType',
          typeof this.ruleForm.industryType != 'undefined' &&
            this.ruleForm.industryType != null &&
            this.ruleForm.industryType != ''
            ? this.ruleForm.industryType
            : 0
        )
        formData.append(
          'industryTypeEtc',
          typeof this.ruleForm.industryTypeEtc != 'undefined'
            ? this.ruleForm.industryTypeEtc
            : ''
        )
        let member = []
        this.ruleForm.memberOf.forEach((data) => {
          member.push({number: data.memberOf, etc: data.memberOfEtc})
        })
        formData.append(
          'memberOf',
          typeof this.ruleForm.memberOf != 'undefined'
            ? JSON.stringify(member)
            : ''
        )
        formData.append(
          'memberOfEtc',
          typeof this.ruleForm.memberOfEtc != 'undefined'
            ? this.ruleForm.memberOfEtc
            : ''
        )
        formData.append(
          'employment',
          typeof this.ruleForm.employment != 'undefined'
            ? this.ruleForm.employment
            : 0
        )
        formData.append(
          'yearEstablished',
          typeof this.ruleForm.yearEstablished != 'undefined'
            ? this.ruleForm.yearEstablished
            : ''
        )
        formData.append(
          'latestAnnualRevenues',
          typeof this.ruleForm.latestAnnualRevenues != 'undefined'
            ? this.ruleForm.latestAnnualRevenues
            : 0
        )
        formData.append(
          'latestAnnualProfits',
          typeof this.ruleForm.latestAnnualProfits != 'undefined'
            ? this.ruleForm.latestAnnualProfits
            : 0
        )
        formData.append(
          'exportExperience',
          typeof this.ruleForm.exportExperience != 'undefined' &&
            this.ruleForm.exportExperience != null &&
            this.ruleForm.exportExperience != ''
            ? this.ruleForm.exportExperience
            : 0
        )
        formData.append(
          'exportRatio',
          typeof this.ruleForm.exportRatio != 'undefined' &&
            this.ruleForm.exportRatio != null &&
            this.ruleForm.exportRatio != ''
            ? this.ruleForm.exportRatio
            : 0
        )
        formData.append(
          'exportSales',
          typeof this.ruleForm.exportSales != 'undefined' &&
            this.ruleForm.exportSales != null &&
            this.ruleForm.exportSales != ''
            ? this.ruleForm.exportSales
            : 0
        )
        formData.append(
          'orderConsistency',
          typeof this.ruleForm.orderConsistency != 'undefined' &&
            this.ruleForm.orderConsistency != null &&
            this.ruleForm.orderConsistency != ''
            ? this.ruleForm.orderConsistency
            : 0
        )
        formData.append(
          'exportFrequency',
          typeof this.ruleForm.exportFrequency != 'undefined' &&
            this.ruleForm.exportFrequency != null &&
            this.ruleForm.exportFrequency != ''
            ? this.ruleForm.exportFrequency
            : 0
        )
        formData.append(
          'exportCountry',
          typeof this.ruleForm.exportCountry != 'undefined' &&
            this.ruleForm.exportCountry != null &&
            this.ruleForm.exportCountry != ''
            ? this.ruleForm.exportCountry
            : 0
        )
        formData.append(
          'trainingExpectations',
          typeof this.ruleForm.trainingExpectations != 'undefined' &&
            this.ruleForm.trainingExpectations != null &&
            this.ruleForm.trainingExpectations != ''
            ? this.ruleForm.trainingExpectations
            : 0
        )
        formData.append(
          'moreExportExpectations',
          typeof this.ruleForm.moreExportExpectations != 'undefined' &&
            this.ruleForm.moreExportExpectations != null &&
            this.ruleForm.moreExportExpectations != ''
            ? this.ruleForm.moreExportExpectations
            : 0
        )
        formData.append(
          'expectedPercentage',
          typeof this.ruleForm.expectedPercentage != 'undefined' &&
            this.ruleForm.expectedPercentage != null &&
            this.ruleForm.expectedPercentage != ''
            ? this.ruleForm.expectedPercentage
            : 0
        )
        formData.append(
          'expectedPercentageEtc',
          typeof this.ruleForm.expectedPercentageEtc != 'undefined' &&
            this.ruleForm.expectedPercentageEtc != '' &&
            this.ruleForm.expectedPercentageEtc != null
            ? this.ruleForm.expectedPercentageEtc
            : 0
        )
        formData.append(
          'consent',
          typeof this.ruleForm.consent != 'undefined'
            ? this.ruleForm.consent
            : 0
        )
        formData.append(
          'companyNameEdit',
          typeof this.ruleForm.companyNameEdit != 'undefined' &&
            this.ruleForm.companyNameEdit != null &&
            this.ruleForm.companyNameEdit != ''
            ? this.ruleForm.companyNameEdit
            : ''
        )
        formData.append(
          'operatorType',
          typeof this.ruleForm.operatorType != 'undefined' &&
            this.ruleForm.operatorType != null &&
            this.ruleForm.operatorType != ''
            ? this.ruleForm.operatorType
            : 0
        )
        formData.append(
          'exportPlan',
          typeof this.ruleForm.exportPlan != 'undefined' &&
            this.ruleForm.exportPlan != '' &&
            this.ruleForm.exportPlan != null
            ? this.ruleForm.exportPlan
            : 0
        )
        formData.append(
          'isProduction',
          typeof this.ruleForm.isProduction != 'undefined'
            ? this.ruleForm.isProduction
            : ''
        )
        formData.append(
          'shipment',
          typeof this.ruleForm.shipment != 'undefined' &&
            this.ruleForm.shipment != '' &&
            this.ruleForm.shipment != null
            ? this.ruleForm.shipment
            : 0
        )
        formData.append(
          'paymentTerm',
          typeof this.ruleForm.paymentTerm != 'undefined' &&
            this.ruleForm.paymentTerm != '' &&
            this.ruleForm.paymentTerm != null
            ? this.ruleForm.paymentTerm
            : 0
        )

        formData.append(
          'exportValue',
          typeof this.ruleForm.exportValue != 'undefined' &&
            this.ruleForm.exportValue != '' &&
            this.ruleForm.exportValue != null
            ? this.ruleForm.exportValue
            : 0
        )

        formData.append('_csrf', this.$csrfToken)
        let res = await HTTP.put(`user`, formData, config).catch((e) => {
          this.$message.error(e)
          console.log(`put/user error`, e)
        })
        return true
      },
    },
    async mounted() {
      try {
        this.signinRedirectCallback()
      } catch (e) {
        console.log('mounted :', e)
        this.data = 'Error'
        this.loading = false
      }
    },
  }
</script>
